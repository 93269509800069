<template>
    <div v-if="mbundle.authors" class="authors">
        <div class="author" v-for="(author, index) in mbundle.authors" :key="`author-${index}`">
            <img :src="author.avatar" :alt="author.name" loading="lazy" />
            <div class="details">
                <h4 v-if="author.name">
                    {{ author.name }}
                </h4>
                <h5 v-if="author.role">
                    {{ author.role }}
                </h5>
                <span v-if="author.description" v-html="author.description"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpaceSingleMbundleHome',
    props: {
        mbundle: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            labels: {}
        }
    },
    computed: {
        
    },
    methods: {
        
    },
    components: {
        
    }
}
</script>

<style scoped lang="scss">
    @import "@/styles/common";

    .authors {
        .author {
            background-color: #fff;
            box-shadow: $box_shadow;
            display: flex;
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0px;
            }

            img {
                width: 220px;
                height: auto;
                object-fit: contain;
                object-position: center;
            }

            .details {
                padding: 1rem;
                margin-left: 1rem;
                border-left: 1px solid rgba(0,0,0,0.1);

                h4 {
                    font-size: 1.3rem;
                    margin-bottom: 0.3rem;
                }

                h5 {
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
</style>